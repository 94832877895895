import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js"

class InputFormatter extends Controller {
  static targets = ["input"]

  static values = { format: String, options: Object }

  connect() {
    if (this.formatValue.length > 0) {
      this.cleave = new Cleave(this.inputTarget, this.getFormatOptions())
    } else {
      this.cleave = new Cleave(this.inputTarget, this.optionsValue)
    }
  }

  getFormatOptions() {
    switch (this.formatValue) {
      case "ssn":
        return this.ssnOptions()
        break
      case "phone":
        return this.phoneOptions()
        break
      case "zipcode":
        return this.zipCodeOptions()
        break
      case "houseNumber":
        return this.houseNumberOptions()
        break
      case "currency":
        return this.currencyOptions()
        break
      case "date":
        return this.dateOptions()
        break
      case "year":
        return this.yearOptions()
        break
    }
  }

  yearOptions() {
    return {
      date: true,
      delimiter: "",
      datePattern: ["Y"],
    }
  }

  houseNumberOptions() {
    return { blocks: [10], uppercase: true }
  }

  ssnOptions() {
    return {
      delimiter: "-",
      blocks: [3, 2, 4],
    }
  }

  phoneOptions() {
    return {
      delimiters: ["(", ")", " ", "-"],
      blocks: [0, 3, 0, 3, 4],
      uppercase: true,
    }
  }

  zipCodeOptions() {
    return { blocks: [5], uppercase: true }
  }

  currencyOptions() {
    return {
      numeral: true,
      stripLeadingZeroes: true,
      numeralPositiveOnly: true,
      numeralDecimalScale: 2,
    }
  }

  dateOptions() {
    return {
      date: true,
      delimiter: "/",
      datePattern: ["m", "d", "Y"],
    }
  }

  disconnect() {
    this.cleave.destroy()
  }

  declare formatValue: String
  declare optionsValue: Object
  declare inputTarget: HTMLInputElement
  declare cleave: Cleave
}

export default InputFormatter
