import { Controller } from "@hotwired/stimulus";

class Reminders extends Controller {
 static targets = ["trigger", "oneOffMessage", "plannedMessage", "checkbox"];  

  connect() {
    if(this.checkboxTarget.checked) {
      this.oneOffMessageTarget.classList.remove("hidden")
      this.plannedMessageTarget.classList.add("hidden")
    }
    else if(!this.checkboxTarget.checked) {
      this.oneOffMessageTarget.classList.add("hidden")
      this.plannedMessageTarget.classList.remove("hidden")
    }
  }

  toggle(e: any) {
    if(e.target.checked) {
      this.oneOffMessageTarget.classList.remove("hidden")
      this.plannedMessageTarget.classList.add("hidden")
    }
    else if(!e.target.checked) {
      this.oneOffMessageTarget.classList.add("hidden")
      this.plannedMessageTarget.classList.remove("hidden")
    }
  }

  declare triggerTarget: HTMLElement;
  declare triggerTargets: Array<HTMLElement>;
  declare oneOffMessageTarget: HTMLElement;
  declare plannedMessageTarget: HTMLElement;
  declare checkboxTarget: HTMLElement;
}

export default Reminders