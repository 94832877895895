import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

class AsSlimSelect extends Controller {
  static targets = ["select"]

  connect() {
    if (this.hasSelectTarget === false) {
      console.warn("No select target found")
      return
    }
    this.instance = new SlimSelect({
      select: this.selectTarget,
    })
  }

  disconnect() {
    if (this.hasSelectTarget === false) {
      return
    }

    this.instance.destroy()
  }

  declare instance: SlimSelect
  declare selectTarget: HTMLSelectElement
  declare hasSelectTarget: boolean
}

export default AsSlimSelect
