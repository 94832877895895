import { Controller } from "@hotwired/stimulus"

class PricingToggle extends Controller {
  static targets = [
    "monthlyButton",
    "annualButton",
    "monthlyPrice",
    "annualPrice",
    "monthlyCtaLink",
    "annualCtaLink",
  ]

  connect() {
    const buttons = [this.monthlyButtonTarget, this.annualButtonTarget]
    buttons.forEach((toggle: HTMLElement) => {
      toggle.addEventListener("click", (e) => {
        e.preventDefault()

        if (e.currentTarget === this.monthlyButtonTarget) {
          this.showMonthlyPrices()
        } else {  
          this.showAnnualPrices()
        }
      })
    })
  }

  showMonthlyPrices() {
    this.monthlyButtonTarget.classList.add("bg-primary-600", "text-white")
    this.annualButtonTarget.classList.remove("bg-primary-600", "text-white")

    this.monthlyPriceTargets.forEach((element: HTMLElement) => {
      element.classList.remove("hidden")
    })

    this.annualPriceTargets.forEach((element: HTMLElement) => {
      element.classList.add("hidden")
    })

    this.monthlyCtaLinkTargets.forEach((element: HTMLElement) => {
      element.classList.remove("hidden")
    })

    this.annualCtaLinkTargets.forEach((element: HTMLElement) => {
      element.classList.add("hidden")
    })
  }

  showAnnualPrices() {
    this.monthlyButtonTarget.classList.remove("bg-primary-600", "text-white")
    this.annualButtonTarget.classList.add("bg-primary-600", "text-white")

    this.monthlyPriceTargets.forEach((element: HTMLElement) => {
      element.classList.add("hidden")
    })

    this.annualPriceTargets.forEach((element: HTMLElement) => {
      element.classList.remove("hidden")
    })

    this.monthlyCtaLinkTargets.forEach((element: HTMLElement) => {
      element.classList.add("hidden")
    })

    this.annualCtaLinkTargets.forEach((element: HTMLElement) => {
      element.classList.remove("hidden")
    })
  }

  declare monthlyButtonTarget: HTMLElement
  declare annualButtonTarget: HTMLElement
  declare monthlyPriceTargets: Array<HTMLElement>
  declare annualPriceTargets: Array<HTMLElement>
  declare monthlyCtaLinkTargets: Array<HTMLElement>
  declare annualCtaLinkTargets: Array<HTMLElement>
}

export default PricingToggle